export const addClass = (_element, className) => {
  const element = _element;
  const classes = (element.className || '').split(' ').filter(p => {
    return p;
  });

  if (classes.indexOf(className) > -1) return;

  classes.push(className);
  element.className = classes.join(' ');
};

export const removeClass = (_element, className) => {
  const element = _element;
  if (element && element.className && typeof element.className === 'string') {
    const classes = element.className.split(' ').filter(p => {
      return p && p !== className;
    });
    element.className = classes.join(' ');
  }
};

export const hasClass = (element, className) => {
  if (element.className && typeof element.className === 'string') {
    const classes = element.className.split(' ');
    return classes.indexOf(className) > -1;
  }
};
