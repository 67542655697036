import getPageCSS from './feedbackEmbedCSS';
import { addClass, hasClass, removeClass } from './utils';

class FeedbackEmbedButton {
  constructor(params) {
    this.developer = params.developer || false;
    this.formID = params.formId;
    this.base = params.base || 'https://form.jotform.com/';
    this.layoutParams = {
      maxHeight: 380,
      minHeight: 325,
      forceHeight: params.forceHeight,
      forceWidth: params.forceWidth,
      additionalQueryParams: params.additionalQueryParams || '', // to pass parameters to iframe url
      containerElement: params.containerElement ? params.containerElement : document.body
    };
    this.init = this.init.bind(this);
    this.createButton = this.createButton.bind(this);
    this.createCloseButton = this.createCloseButton.bind(this);
    this.createIFrame = this.createIFrame.bind(this);
    this.renderIFrame = this.renderIFrame.bind(this);
    this.alignIFrame = this.alignIFrame.bind(this);
    this.openIFrameWrapper = this.openIFrameWrapper.bind(this);
    this.closeIFrameWrapper = this.closeIFrameWrapper.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.receiveMessage = this.receiveMessage.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
    this.handleParameters = this.handleParameters.bind(this);
    this.revealButton = this.revealButton.bind(this);
    this.osName = null;
    this.handleParameters(params);
    this.init();
  }

  init() {
    if (!this.formID) {
      window.alert('no form id provided');
    }
    this.componentID = `JF_feedback_embed_${this.formID}_${new Date().getTime().toString().slice(-4)}`;
    this.layoutParams.iFrameWrapperID = `${this.componentID}_wrapper`;
    this.layoutParams.iFrameID = `${this.componentID}_iframe`;
    this.layoutParams.buttonID = `${this.componentID}_button`;
    this.cssInject();
    this.createButton();
    this.layoutElements = {
      iFrame: document.getElementById(this.layoutParams.iFrameID),
      floatingButton: document.getElementById(this.layoutParams.buttonID)
    };
    // sendAnalyticsData(this.formID, 'feedback_button', {
    //   formID: this.formID,
    //   url: window.location.href
    // }, true);
  }

  renderIFrame() {
    this.createIFrame();
    const { iFrameWrapper, floatingButton } = this.layoutElements;
    this.alignIFrame();
    addClass(iFrameWrapper, 'jFisOpen');
    addClass(floatingButton, 'jFisActive');
  }

  alignIFrame() {
    const { iFrameWrapper } = this.layoutElements;
    iFrameWrapper.style.top = this.buttonSide === 'Top' ? '84px' : null;
    iFrameWrapper.style.bottom = this.buttonSide === 'Bottom' ? '84px' : null;
    iFrameWrapper.style.left = this.buttonAlign === 'Left' ? '24px' : null;
    iFrameWrapper.style.right = this.buttonAlign === 'Right' ? '24px' : null;
  }

  handleParameters(params) {
    // Button text
    this.buttonText = params.buttonText || 'Give Feedback';

    this.buttonAlign = params.buttonAlign || 'Right';
    this.buttonSide = params.buttonSide || 'Bottom';
    // Button position
    this.buttonPosition = `${this.buttonSide.toLowerCase()}${this.buttonAlign}`;

    // Button colors
    // TODO :: may update default colors?
    this.buttonBackgroundColor = params.buttonBackgroundColor || '#007FEF';
    this.buttonFontColor = params.buttonFontColor || '#ffffff';

    // Button icon class
    this.buttonIcon = (params.buttonIcon && params.buttonIcon !== 'default') ? params.buttonIcon : 'textOnly';
    this.buttonClassName = `jfFeedbackButton u-${this.buttonIcon}`;
  }

  createButton() {
    const { buttonID, containerElement } = this.layoutParams;
    const button = document.createElement('button');
    const iconSpan = document.createElement('span');
    const textSpan = document.createElement('span');
    iconSpan.className = `jfFeedbackButton-icon u-${this.buttonIcon}`;
    textSpan.className = 'jfFeedbackButton-text';
    button.className = `${this.buttonClassName} u-${this.buttonPosition}`;
    button.id = buttonID;
    button.style.display = 'none';

    textSpan.appendChild(document.createTextNode(this.buttonText));
    button.appendChild(iconSpan);
    button.appendChild(textSpan);

    button.addEventListener('click', this.openIFrameWrapper);
    containerElement.appendChild(button);
  }

  openIFrameWrapper() {
    const { iFrame, iFrameWrapper = false, floatingButton } = this.layoutElements;
    if (iFrameWrapper) {
      addClass(iFrameWrapper, 'jFisOpen');
      addClass(floatingButton, 'jFisActive');
    } else {
      this.renderIFrame();
      return;
    }
    setTimeout(() => {
      window.addEventListener('click', this.handleOutsideClick);
      iFrame.contentWindow.postMessage(JSON.stringify({ messageType: 'buttonClick' }), '*');
      floatingButton.removeEventListener('click', this.openIFrameWrapper);
      floatingButton.addEventListener('click', this.closeIFrameWrapper);
    }, 100);
  }

  closeIFrameWrapper() {
    const { iFrameWrapper, floatingButton } = this.layoutElements;
    if (iFrameWrapper) {
      removeClass(iFrameWrapper, 'jFisOpen');
      removeClass(floatingButton, 'jFisActive');

      window.removeEventListener('click', this.handleOutsideClick);
      floatingButton.removeEventListener('click', this.closeIFrameWrapper);
      floatingButton.addEventListener('click', this.openIFrameWrapper);
    }
  }

  handleOutsideClick(e) {
    if (!hasClass(e.target, 'jfFeedbackButton')) {
      this.closeIFrameWrapper();
      window.removeEventListener('click', this.handleOutsideClick);
    }
  }

  createIFrame() {
    const iFrameWrapper = document.createElement('div');
    const {
      iFrameID, iFrameWrapperID, containerElement, additionalQueryParams
    } = this.layoutParams;
    iFrameWrapper.id = iFrameWrapperID;
    iFrameWrapper.style.display = 'none';
    iFrameWrapper.className = 'jfFeedbackButton-modal';

    const iFrame = document.createElement('iframe');
    iFrame.id = iFrameID;
    iFrame.allow = 'geolocation; microphone; camera; autoplay; encrypted-media; fullscreen';

    let url;
    if (this.developer) {
      url = `https://${this.developer}.jotform.pro/${this.formID}`;
    } else {
      url = `${this.base}${this.formID}`;
    }
    url += `?feedbackEmbedButton&noToolBar&disableSmartEmbed=1${additionalQueryParams}`;

    iFrame.src = url;
    this.layoutElements = { ...this.layoutElements, iFrameWrapper: iFrameWrapper };
    window.addEventListener('message', this.receiveMessage);
    this.createCloseButton(iFrameWrapper);
    iFrameWrapper.appendChild(iFrame);
    containerElement.appendChild(iFrameWrapper);
  }

  createCloseButton(wrapper) {
    const closeButton = document.createElement('div');
    closeButton.className = 'close-btn';
    closeButton.innerHTML = '&times';
    closeButton.addEventListener('click', this.handleOutsideClick);
    wrapper.appendChild(closeButton);
  }

  updateHeight(questionData) {
    const {
      maxHeight, minHeight, forceHeight, forceWidth
    } = this.layoutParams;
    const { iFrameWrapper } = this.layoutElements;
    const { questionHeight, bottomBarHeight } = questionData;
    // const iFrameHeight = iFrame.getBoundingClientRect().height;
    const totalInnerHeight = questionHeight + bottomBarHeight + 8; // paddings
    let newHeight = totalInnerHeight;

    if (totalInnerHeight >= maxHeight) {
      newHeight = maxHeight;
    } else if (totalInnerHeight <= minHeight) {
      newHeight = minHeight;
    }

    newHeight = forceHeight || newHeight;
    if (forceWidth) {
      iFrameWrapper.style.width = `${forceWidth}px`;
    }

    this.layoutParams.minHeight = newHeight;
    iFrameWrapper.style.height = `${newHeight}px`;
  }

  setDeviceType(deviceType) {
    const { iFrameWrapper, floatingButton } = this.layoutElements;
    addClass(iFrameWrapper, `jFis${deviceType}`);
    addClass(floatingButton, `jFis${deviceType}`);
  }

  setOS(osName) {
    const { iFrameWrapper, floatingButton } = this.layoutElements;
    this.osName = osName;
    addClass(floatingButton, `jFis${osName}`);
    addClass(iFrameWrapper, `jFis${osName}`);
  }

  receiveMessage(event) {
    // We should remove messages coming from jotform.js
    const eventData = event.data;
    if (typeof eventData === typeof {} && typeof eventData.messageType === 'string') {
      const { messageType } = eventData;
      switch (messageType) {
        case 'setDeviceType':
          this.setDeviceType(eventData.deviceType);
          break;
        case 'setOS':
          this.setOS(eventData.OS);
          break;
        // eslint-disable-next-line default-case-last
        default:
        case 'updateHeight':
          this.updateHeight(eventData);
          break;
      }
    }
  }

  revealButton() {
    const { floatingButton } = this.layoutElements;
    floatingButton.style.display = 'inline-block';
  }

  /* CSS Parts
   * let Omer to use this part - ARO
  */
  appendStyles(template) {
    const styleElement = global.document.createElement('style');
    styleElement.setAttribute('type', 'text/css');
    styleElement.innerHTML = template;
    global.document.getElementsByTagName('head')[0].appendChild(styleElement);

    const url = this.developer ? `https://${this.developer}.jotform.pro/cardforms/feedbackEmbed.css` : 'https://cdn.jotfor.ms/cardforms/feedbackEmbed.css';
    const linkElement = global.document.createElement('link');
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.onload = () => {
      this.revealButton();
    };

    global.document.getElementsByTagName('head')[0].appendChild(linkElement);
  }

  cssInject() {
    const pageCSS = getPageCSS(this.buttonBackgroundColor, this.buttonFontColor);
    this.appendStyles(pageCSS);
  }

  /* END CSS Parts */
}

global.JF_FeedbackEmbedButton = FeedbackEmbedButton;
